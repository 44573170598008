<template>
    <div class="letterinfo">
        <a-spin :spinning="loading">
            <div class="lettertitle">
                <h1>{{info.Title}}</h1>
                <div class="time">{{info.UpdateTime.split(' ')[0].split('/').join('-')}}</div>
            </div>
            <div class="content" v-html="info.Content"></div>
            <div class="back">
                <a-button @click="goBack">返回列表</a-button>
            </div>
        </a-spin>
    </div>
</template>
<script>
import { GetSingleDataById } from '@/api/common'
export default {
    metaInfo() {
		return {
			title: this.info.Title || '站内信-个人中心-文物出版社',
			meta: [
                {
                    name: 'keyWords',
					content: this.info.Title || '站内信-个人中心-文物出版社'
                },
                {
                    name: 'description',
					content: this.info.Title || '站内信-个人中心-文物出版社'
                }
            ]
		}
	},
    data(){
        return{
            info:{
                Content:'',
                UpdateTime:''
            },
            params:{
                id:'',
                columnId:10032
            },
            loading:false,
        }
    },
    methods:{
        async getData(){
            this.loading = true
            const { data } = await GetSingleDataById(this.params)
            this.info = data
            this.loading = false
        },
        goBack(){
            this.$router.push({name:'letter'})
        }
    },
    mounted(){
        this.params.id = this.$route.params.id
        this.getData()
    }
}
</script>
<style lang="less" scoped>
.letterinfo{
    padding: 20px 40px;
}
.lettertitle{
    text-align: center;
    padding: 20px 0 30px;
    border-bottom: 1px solid #e5e5e5;
    h1{
        font-size: 18px;
        color: rgb(51, 51, 51);
        line-height: 1.333;
    }
    .time{
        font-size: 16px;
        color: rgb(136, 136, 136);
        line-height: 1.75;
    }
}
.content{
    padding: 20px 0;
    min-height: 150px;
}
.back{
    text-align: center;
    padding: 20px 0;
    /deep/.ant-btn{
        min-width: 150px;
        padding: 0 40px;
        height: 50px;
        line-height: 50px;
        border-radius: 30px;
        background-color: #00ffc0;
        color: #000;
        font-size: 20px;
        border-color: #00ffc0;
        &:hover{
            background-color: #79f9d9;
            border-color: #79f9d9;
            box-shadow: 0 0 0.1rem 0 rgba(121, 249, 217, 0.8);
        }
    }
}
</style>